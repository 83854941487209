import React, { useMemo } from "react";
import {
  useMaterialReactTable,
  MaterialReactTable,
} from "material-react-table";
import { pageSize } from "../../../constants/constant";

const Table = ({ jsonData, columnFields, onClickHandler }) => {
  const formatDate = (dateString, key) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    if (key === "LoginTime") {
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    } else {
      return `${day}/${month}/${year}`;
    }
  };

  const formattedData = useMemo(() => {
    return jsonData.map((item) => {
      const newItem = { ...item };
      ["CreatedOn", "ModifiedOn", "LoginTime"].forEach((key) => {
        if (newItem[key]) {
          newItem[key] = formatDate(newItem[key], key);
        }
      });
      return newItem;
    });
  }, [jsonData]);

  const columns = useMemo(() => {
    if (!formattedData[0]) {
      return [];
    }
    //console.log("column fields: ", columnFields);

    const firstItem = formattedData[0];
    const columnFieldLookup = columnFields.reduce((acc, field) => {
      const key = Object.keys(field)[0];
      acc[key] = field[key];
      return acc;
    }, {});

    return Object.keys(firstItem).flatMap((key) => {
      if (!columnFieldLookup[key]) {
        return [];
      }

      if (typeof firstItem[key] === "object" && firstItem[key] !== null) {
        return columnFields.map((field) => {
          const key = Object.keys(field)[0];
          return {
            accessorKey: `${key}`,
            header: field[key],
            size: 150,
          };
        });
      } else {
        return [
          {
            accessorKey: key,
            header: columnFieldLookup[key],
            size: 150,
          },
        ];
      }
    });
  }, [formattedData, columnFields]);

  const data = useMemo(() => [...formattedData], [formattedData]);

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: { pagination: pageSize },

    // initialState: { columnVisibility: { Id: false } },
    muiCircularProgressProps: {
      color: "secondary",
      thickness: 5,
      size: 55,
    },

    enablePagination: true,
    enableStickyHeader: true,
    muiTableContainerProps: {
      sx: { maxHeight: "50vh", overflow: "auto", minHeight: "45vh" },
    },

    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        backgroundColor: row.index % 2 === 0 ? "#4bade00a" : "#fff",
      },
    }),
    muiTableBodyCellProps: ({ cell }) => ({
      onClick: (event) => {
        onClickHandler(cell, formattedData);
      },
    }),
  });

  return (
    <div className="tableContainer">
      {/* <p>table</p> */}
      <MaterialReactTable table={table} />
    </div>
  );
};

export default Table;
