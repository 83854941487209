import React, { useState } from "react";
import {
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import {
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  FormatListBulleted,
  FormatListNumbered,
  InsertLink,
  Image,
  FormatColorText,
  TableChart,
} from "@mui/icons-material";

const Toolbar = ({ editorRef, onImageInsert }) => {
  const [openTableDialog, setOpenTableDialog] = useState(false);
  const [tableRows, setTableRows] = useState(2);
  const [tableColumns, setTableColumns] = useState(2);

  const execCommand = (command, value = null) => {
    //console.log("execCommand", command, value);
    document.execCommand(command, false, value);
    editorRef.current.focus();
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      onImageInsert(file);
    }
  };

  const handleInsertTable = () => {
    //console.log("Insert Table", tableRows, tableColumns);
    let tableHTML = '<table border="1" style="border-collapse: collapse;">';
    for (let i = 0; i < tableRows; i++) {
      tableHTML += "<tr>";
      for (let j = 0; j < tableColumns; j++) {
        tableHTML += '<td style="padding: 5px;">Cell</td>';
      }
      tableHTML += "</tr>";
    }
    tableHTML += "</table>";
    //console.log("tableHTML", tableHTML);
    execCommand("insertHTML", tableHTML);
    setOpenTableDialog(false);
  };
  return (
    <div
      className="toolbar"
      style={{ display: "flex", alignItems: "center", gap: "8px" }}
    >
      <FormControl variant="outlined" size="small" style={{ minWidth: 120 }}>
        <InputLabel id="font-size-label">Font Size</InputLabel>
        <Select
          labelId="font-size-label"
          label="Font Size"
          onChange={(e) => execCommand("fontSize", e.target.value)}
          defaultValue=""
        >
          <MenuItem value="3">JMS - Paragraph</MenuItem>
          <MenuItem value="4">JMS - H1</MenuItem>
          <MenuItem value="4">JMS - Title</MenuItem>
          {/* <MenuItem value="7">Extra Large</MenuItem> */}
        </Select>
      </FormControl>

      <Tooltip title="Bold">
        <IconButton onClick={() => execCommand("bold")}>
          <FormatBold />
        </IconButton>
      </Tooltip>
      <Tooltip title="Italic">
        <IconButton onClick={() => execCommand("italic")}>
          <FormatItalic />
        </IconButton>
      </Tooltip>
      <Tooltip title="Underline">
        <IconButton onClick={() => execCommand("underline")}>
          <FormatUnderlined />
        </IconButton>
      </Tooltip>

      <Tooltip title="Text Color">
        <IconButton component="label">
          <FormatColorText />
          <input
            className="color-input"
            type="color"
            style={{ opacity: 0, width: 0, height: 0 }}
            onChange={(e) => execCommand("foreColor", e.target.value)}
          />
        </IconButton>
      </Tooltip>

      <Tooltip title="Ordered List">
        <IconButton onClick={() => execCommand("insertOrderedList")}>
          <FormatListNumbered />
        </IconButton>
      </Tooltip>
      <Tooltip title="Unordered List">
        <IconButton onClick={() => execCommand("insertUnorderedList")}>
          <FormatListBulleted />
        </IconButton>
      </Tooltip>

      {/* <Tooltip title="Insert Image">
                <IconButton component="label">
                    <Image />
                    <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={handleImageUpload}
                    />
                </IconButton>
            </Tooltip> */}

      <Tooltip title="Insert Link">
        <IconButton
          onClick={() => {
            const url = prompt("Enter the URL:");
            if (url) execCommand("createLink", url);
          }}
        >
          <InsertLink />
        </IconButton>
      </Tooltip>

      {/* <Tooltip title="Insert Table">
                <IconButton onClick={() => setOpenTableDialog(true)}>
                    <TableChart />
                </IconButton>
            </Tooltip>

            <Dialog open={openTableDialog} onClose={() => setOpenTableDialog(false)}>
                <DialogTitle>Insert Table</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Rows"
                        type="number"
                        fullWidth
                        value={tableRows}
                        onChange={(e) => setTableRows(Number(e.target.value))}
                    />
                    <TextField
                        margin="dense"
                        label="Columns"
                        type="number"
                        fullWidth
                        value={tableColumns}
                        onChange={(e) => setTableColumns(Number(e.target.value))}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenTableDialog(false)}>Cancel</Button>
                    <Button onClick={handleInsertTable}>Insert</Button>
                </DialogActions>
            </Dialog> */}
    </div>
  );
};

export default Toolbar;
