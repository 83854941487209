// LoaderContext.js
import React, { createContext, useState, useContext } from "react";
import Loader from "../shared/components/loader/loader";

const LoaderContext = createContext();

export const LoaderProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const toggleLoader = (show) => setIsLoading(show);

  return (
    <LoaderContext.Provider value={{ isLoading, toggleLoader }}>
      {isLoading && <Loader />}
      {children}
    </LoaderContext.Provider>
  );
};

export const useLoader = () => {
  const context = useContext(LoaderContext);
  if (!context) {
    throw new Error("useLoader must be used within a LoaderProvider");
  }
  return context;
};
