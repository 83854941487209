import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./addDocument.css";
import PageHeader from "../../../../shared/components/pageHeader/PageHeader";
import { useAuth } from "../../../../context/authContext";
import { notify } from "../../../../util/notify";
import {
  addFormDocumentData,
  getApi,
  postApi,
  putApi,
  uploadFormData,
} from "../../../../util/HttpService";
import { useData } from "../../../../context/dataContext";
import { useNavigate } from "react-router-dom";
import { useDms } from "../../../../context/dmsContext";
export const AddDocument = () => {
  const navigate = useNavigate();
  const { userId, userName } = useAuth();
  const {
    ownerDepartmentList,
    setDocumentData,
    documentData: docData,
    elementListData,
  } = useData();
  //  const {
  //    ownerDepartmentList,
  //    elementListData,
  //    documentData: docData,
  //  } = useData();
  const { setFormInfo, setFormData, formData, updateSidebarData } = useDms();
  const addDocument = async (values) => {
    const endpoint = "addDocument";
    try {
      const data = await addFormDocumentData(endpoint, values, true);
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  const handleUpload = async (file) => {
    //console.log("file received in the function", file);
    if (!file) {
      throw new Error("Please select a file to upload");
    }
    try {
      const formData = new FormData();
      formData.append("file", file);
      const currentDate = Date.now();
      formData.append("timeStamp", currentDate);
      const response = await uploadFormData("uploadpdffile", file, true);
      //console.log("response", response);
      if (response.success === false) {
        throw new Error(response.error || "File upload failed");
      }
      return response;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  const uploadFormToGlobalSearch = async (values) => {
    try {
      const response = await putApi(
        "documentContent/addToSearchIndex",
        values,
        true
      );
      //console.log("response: ", response, " values: ", values);
      //console.log("response: ", response);
      if (!response.success) {
        throw new Error(response.error || "File upload failed");
      }
      return response;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  const validationSchema = Yup.object({
    documentName: Yup.string()
      .required("Document name is required")
      .max(150, "Document name should not exceed 150 characters")
      .test("is-not-only-space", "Kindly enter valid value", (value) => {
        return value.trim().length > 0;
      }),
    categoryId: Yup.string().required("Element is required"),
    Version: Yup.number()
      .required("Version number is required")
      .test("is-decimal", "Invalid version number", (value) => {
        return /^\d*\.?\d+$/.test(value.toString());
      })
      .min(0, "Version number cannot be negative")
      .max(100.0, "Version number should not exceed 100.0"),
    documentNo: Yup.string()
      .required("Document number is required")
      .max(50, "Document number should not exceed 50 characters")
      .test("is-not-only-space", "Kindly enter valid value", (value) => {
        return value.trim().length > 0;
      }),
    file: Yup.mixed()
      .required("File is required")
      .test("is-docx", "Document should be in docx format", (value) => {
        return (
          value &&
          value.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        );
      })
      .test("file-size", "File size should not exceed 60MB", (value) => {
        return value && value.size <= 60 * 1024 * 1024;
      }),
  });

  const formik = useFormik({
    initialValues: {
      documentName: "",
      categoryId: "",
      Version: "",
      // ownerName: "",
      ownerDepartment: "",
      documentNo: "",
      file: null,
    },
    validationSchema: validationSchema,

    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        // //console.log("Form Values", values);
        //console.log("File value", values.file);
        const uploadResponse = await handleUpload(values.file);
        //console.log("upload response", uploadResponse);
        // file Id from response +  Timestamp
        if (uploadResponse.success === true) {
          const documentData = {
            DocumentNo: values.documentNo,
            DocumentName: values.documentName,
            DocumentType: "docx",
            DocumentPath: elementListData.find(
              (category) => category.Id == values.categoryId
            ).ElementName,
            DocumentVersion: values.Version,
            // OwnerName: values.ownerName,
            OwnerDepartment: values.ownerDepartment,
            ElementId: values.categoryId,
            UpdatedBy: userId,
            DocUrl: uploadResponse.convertedDocUrl,
            OriginalUrl: uploadResponse.originalDocUrl,
          };
          const addDocumentResponse = await addDocument(documentData);

          if (
            addDocumentResponse.success == true &&
            uploadResponse.success == true
          ) {
            const newData = [
              ...docData,
              {
                CreatedOn: new Date().toISOString(),
                DocUrl: uploadResponse.convertedDocUrl,
                DocumentName: values.documentName,
                DocumentNo: values.documentNo,
                DocumentPath: elementListData.find(
                  (category) => category.Id == values.categoryId
                ).ElementName,
                DocumentType: "docx",
                DocumentVersion: values.Version,
                ElementId: values.categoryId,
                Id: addDocumentResponse.docId,
                ModifiedOn: "",
                OriginalUrl: uploadResponse.originalDocUrl,
                OwnerDepartment: values.ownerDepartment,
                OwnerName: null,
                UpdatedBy: userId || "",
                UpdatedByName: userName || "",
              },
            ];
            setDocumentData(newData);
            updateSidebarData(elementListData, newData, formData);

            const response = await uploadFormToGlobalSearch({
              docId: addDocumentResponse.docId,
              fileId: uploadResponse.fileId,
            });

            if (response.success === true) {
              notify("Document added successfully", "success");
            }
            resetForm();
          } else {
            //console.log("Document addition failed");
            notify("Document addition failed", "error");
            resetForm();
          }
        } else if (uploadResponse.success === false) {
          resetForm();
          notify("Document addition failed", "error");
        }
      } catch (error) {
        console.error("Error in form submission:", error);
        // notify(error.message || "An error occurred", "error");
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <div className="docUpload">
      <PageHeader title="Upload Document" />
      <form onSubmit={formik.handleSubmit} className="formContainer">
        <div className="form">
          <div className="formInputs">
            <div className="formSubContainer">
              <div className="DisplayName">
                <p className="label">Document Display Name</p>
                <input
                  type="text"
                  id="documentName"
                  name="documentName"
                  placeholder="Enter Document Display Name"
                  className="formInputsText"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.documentName}
                  maxLength={150}
                />
                {formik.touched.documentName && formik.errors.documentName ? (
                  <div className="error">{formik.errors.documentName}</div>
                ) : null}
              </div>
              <div className="DocumentNumber">
                <p className="label">Document Number</p>
                <input
                  type="text"
                  id="documentNo"
                  name="documentNo"
                  placeholder="Enter Document Number"
                  min={0}
                  className="formInputsText"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.documentNo}
                  maxLength={50}
                />
                {formik.touched.documentNo && formik.errors.documentNo ? (
                  <div className="error">{formik.errors.documentNo}</div>
                ) : null}
              </div>
            </div>
            <div className="formSubContainer">
              <div className="Version">
                <p className="label">Version Number</p>
                <input
                  type="text"
                  min={0}
                  id="Version"
                  name="Version"
                  placeholder="Enter Version Number"
                  className="formInputsText"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.Version}
                  maxLength={5}
                />
                {formik.touched.Version && formik.errors.Version ? (
                  <div className="error">{formik.errors.Version}</div>
                ) : null}
              </div>
              {/* <div className="OwnerName">
                                <p className="label">Owner name</p>
                                <input
                                    type="text"
                                    id="ownerName"
                                    name="ownerName"
                                    placeholder="Enter owner name"
                                    className="formInputsText"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.ownerName}
                                    maxLength={50}
                                />
                                {formik.touched.ownerName && formik.errors.ownerName ? (
                                    <div className="error">{formik.errors.ownerName}</div>
                                ) : null}
                            </div> */}
              <div className="OwnerDepartment">
                <p className="label">Owners</p>
                <select
                  id="ownerDepartment"
                  name="ownerDepartment"
                  className="formInputsDrpDown"
                  placeholder="Select Owner Department"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ownerDepartment}
                >
                  <option value="">Select</option>
                  {ownerDepartmentList.map((department) => (
                    <option key={department.Id} value={department.Id}>
                      {department.Name}
                    </option>
                  ))}
                </select>
                {formik.touched.ownerDepartment &&
                formik.errors.ownerDepartment ? (
                  <div className="error">{formik.errors.ownerDepartment}</div>
                ) : null}
              </div>
            </div>
            <div className="formSubContainer">
              <div className="Category">
                <p className="label">SMS Element</p>
                <select
                  id="categoryId"
                  name="categoryId"
                  className="formInputsDrpDown"
                  placeholder="Select Element"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.categoryId}
                >
                  <option value="">Select Element</option>
                  {elementListData.map((category) => (
                    <option value={category.Id} key={category.Id}>
                      {category.ElementName}
                    </option>
                  ))}
                </select>
                {formik.touched.categoryId && formik.errors.categoryId ? (
                  <div className="error">{formik.errors.categoryId}</div>
                ) : null}
              </div>
              <div className="DocUpload">
                <p className="label">Document Upload</p>
                <input
                  type="file"
                  // id="file"
                  key={formik.values.categoryId}
                  name="file"
                  id="fileInput"
                  multiple={false}
                  className="file-input"
                  placeholder="Select Document"
                  accept=".docx"
                  onChange={(event) => {
                    const selectedFile = event.currentTarget.files[0];
                    formik.setFieldValue("file", selectedFile);
                    //console.log("formik file value is ", selectedFile);
                  }}
                />
                {formik.touched.file && formik.errors.file ? (
                  <div className="error">{formik.errors.file}</div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="BtnContainer">
          <button
            type="button"
            className="ActionButtons"
            onClick={() => navigate(-1)}
          >
            Cancel
          </button>
          {/* <button type="button" className="ActionButtons" onClick={formik.handleReset}>Cancel</button> */}
          <button
            type="submit"
            className="ActionButtons activeBtn"
            disabled={formik.isSubmitting}
          >
            {formik.isSubmitting ? "Saving..." : "Save"}
          </button>
        </div>
      </form>
    </div>
  );
};
