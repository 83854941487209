import React, { createContext, useContext, useState } from "react";

const DataContext = createContext();

export const useData = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
  const [ownerDepartmentList, setOwnerDepartmentList] = useState([]);
  const [elementListData, setElementListData] = useState([]);
  const [documentData, setDocumentData] = useState([]);

  // //console.log("Element List Data: ", elementListData);
  // //console.log("Owner Department List: ", ownerDepartmentList);
  return (
    <DataContext.Provider
      value={{
        ownerDepartmentList,
        setOwnerDepartmentList,
        elementListData,
        setElementListData,
        documentData,
        setDocumentData,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
