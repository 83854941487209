import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemText,
  styled,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./Accordian.css";
import { useDms } from "../../../context/dmsContext";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  "&.MuiAccordion-root": {
    borderBottom: "1px solid white",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
    },
    "&:before": {
      display: "none",
    },
    "&.Mui-expanded": {
      margin: 0,
    },
    backgroundColor: "white",
    color: "#054163",
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: "#054163",
  },
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.08)",
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: 0,
  backgroundColor: "white",
}));
function NestedAccordion({ items }) {
  const [expanded, setExpanded] = useState(false);
  const { docSubTitle, categoryName } = useDms();

  const handleClick = (panel) => (event) => {
    setExpanded(expanded === panel ? false : panel);
  };

  return (
    <>
      {items.map((item, index) => (
        <StyledAccordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleClick(`panel${index}`)}
        >
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body2">{item.title}</Typography>
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            {Array.isArray(item.content) ? (
              <List>
                {item.content.map((subItem, subIndex) => (
                  <ListItem
                    key={subIndex}
                    button // Ensure the ListItem is clickable
                    onClick={subItem.onClick} // Attach the onClick handler here
                    sx={{
                      backgroundColor:
                        docSubTitle === subItem.title &&
                        categoryName === item.title
                          ? "#E6F4FD"
                          : "white",
                      "&.Mui-selected": {
                        backgroundColor: "#D9F2FF",
                      },
                      pl: 3,
                      pr: 0.5,
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography className="body2" variant="body2">
                          {subItem.title}
                        </Typography>
                      }
                      sx={{ pl: 0, pr: 0.2 }}
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography
                className="body2"
                variant="body2"
                sx={{ pl: 1.5, pr: 0.5 }}
              >
                {item.content}
              </Typography>
            )}
          </StyledAccordionDetails>
        </StyledAccordion>
      ))}
    </>
  );
}

function SidebarAccordion({ sidebarItems }) {
  //console.log("sidebarItems: ", sidebarItems);
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "white",
        color: "#054163",
        overflowY: "auto",
        borderRadius: "31px",
      }}
    >
      <NestedAccordion items={sidebarItems} />
    </Box>
  );
}

export default SidebarAccordion;
