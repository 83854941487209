import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Table from "../../components/Table/Table";
import "./Dashboard.css";
import { useAuth } from "../../../context/authContext";
import Logout from "../../components/logout/Logout";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { getApi } from "../../../util/HttpService";
import SearchBar from "../../components/SearchBar/SearchBar";
import { useData } from "../../../context/dataContext";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { IconButton, Tooltip } from "@mui/material";
import UserManual from "../../../Assets/Files/UserGuide.pdf";
import AdminManual from "../../../Assets/Files/AdminManual.pdf";
import DocumentStructure from "../../../Assets/Files/JMS_Template.docx";
import DownloadIcon from "@mui/icons-material/Download";
import { useDoc } from "../../../context/docContext";
import { useLoader } from "../../../context/loaderContext";
import { notify } from "../../../util/notify";
const Dashboard = () => {
  const { userId, isAdmin } = useAuth();

  // const [lastViewedCount, setLastViewedCount] = useState(0);
  // const [newManualsCount, setNewManualsCount] = useState(0);
  // const [updatedManualsCount, setUpdatedManualsCount] = useState(0);
  const { ownerDepartmentList } = useData();
  const [activeButton, setActiveButton] = useState("newManuals");
  const [documentData, setDocumentData] = useState([]);
  const [readDocumentData, setReadDocumentData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [searchResponse, setSearchResponse] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [lastViewedCount, setLastViewedCount] = useState(0);
  const { setDocData, setGlobalDocUrl } = useDoc();
  const { toggleLoader } = useLoader();
  const [selectedDocument, setSelectedDocument] = useState();
  const navigate = useNavigate();

  const columnFields = [
    { DocumentNo: "Document Number" },
    { DocumentName: "Document Name" },
    { DocumentPath: "Document Path" },
    { DocumentVersion: "Document Version" },
    { CreatedOn: "Created On" },
    { UpdatedByName: "Modified By" },
    { ModifiedOn: "Modified On" },
    { OwnerDepartment: "Owners" },
  ];

  useEffect(() => {
    if (searchResponse.length > 0) {
      setShowSkeleton(true);
      const filteredData = documentData.filter((doc) =>
        searchResponse.find((readDoc) => readDoc.DocUrl === doc.DocUrl)
      );
      //console.log("Filtered Data: ", filteredData);
      setTableData(filteredData);
      setShowSkeleton(false);
      //console.log("Filtered Data: ", filteredData);
    } else {
      filterDocuments(activeButton, documentData);
    }
  }, [searchResponse]);
  const getDocuments = async () => {
    try {
      setShowSkeleton(true);
      const response = await getApi("documentlist", true);
      if (response.success) {
        const data = response.data.data;
        //console.log("Data from the Document API is: ", data);
        setDocumentData(data);
      } else {
        console.error(`Document fetch failed: ${response.error}`);
      }

      setShowSkeleton(false);
    } catch (error) {
      console.error(`Document fetch failed: ${error}`);
    } finally {
    }
  };

  useEffect(() => {
    if (userId) {
      getDocuments();
      getReadDocsData();
    }
  }, [userId]);

  const filterDocuments = (filterType, documents) => {
    let filteredDocuments;
    switch (filterType) {
      case "lastViewed":
        filteredDocuments = readDocumentData;
        break;
      case "newManuals":
        filteredDocuments = documents
          .filter((document) => {
            const last7Days = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
            return new Date(document.CreatedOn.substring(0, 10)) >= last7Days;
          })
          .sort((a, b) => new Date(b.CreatedOn) - new Date(a.CreatedOn));
        // setDocumentData(filteredDocuments);
        break;
      case "updated":
        filteredDocuments = documents
          .filter((document) => {
            if (document.ModifiedOn) {
              const last7Days = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
              return (
                new Date(document.ModifiedOn.substring(0, 10)) >= last7Days
              );
            }
            return null;
          })
          .sort((a, b) => new Date(b.ModifiedOn) - new Date(a.ModifiedOn));
        break;
      default:
        filteredDocuments = documents;
    }
    const modifiedDocuments = filteredDocuments.map((document) => ({
      ...document,
      OwnerDepartment: ownerDepartmentList.find(
        (dept) => dept.Id == document.OwnerDepartment
      )
        ? ownerDepartmentList.find(
            (dept) => dept.Id == document.OwnerDepartment
          ).Name
        : "",
    }));
    // //console.log("Modified Documents: ", modifiedDocuments);
    return modifiedDocuments;
  };

  const getReadDocsData = async () => {
    try {
      const data = await getApi("documentreadDetailsAPI", true);
      setLastViewedCount(data.data.lastViewedDocuments.length);
      setReadDocumentData(
        data.data.lastViewedDocuments.sort((a, b) => b.ReadId - a.ReadId)
      );

      // setReadDocumentData(data.data.lastViewedDocuments);
    } catch (error) {
      //console.log(`Document count fetch failed: ${error}`);
    } finally {
    }
  };
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    // setTableData(filterDocuments(buttonName, documentData));
  };

  const FilteredTableData = useMemo(() => {
    const filteredData = filterDocuments(activeButton, documentData);
    return filteredData;
  }, [activeButton, showSkeleton]);

  const LastViewedCounts = useMemo(() => {
    const filteredData = filterDocuments("lastViewed", documentData);
    return filteredData.length;
  }, [documentData]);

  const UpdatedCount = useMemo(() => {
    const filteredData = filterDocuments("updated", documentData);
    return filteredData.length;
  }, [documentData]);

  const NewManualCount = useMemo(() => {
    const filteredData = filterDocuments("newManuals", documentData);
    return filteredData.length;
  }, [documentData]);

  const handleDownloadDocument = () => {
    const link = document.createElement("a");
    link.href = DocumentStructure;
    link.setAttribute("download", "JMS_Template.docx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadManual = () => {
    let FileName;
    if (isAdmin) {
      FileName = AdminManual;
    } else {
      FileName = UserManual;
    }
    const link = document.createElement("a");
    link.href = FileName;
    const downloadFileName = isAdmin
      ? "User_guide_for_JANA_Marine_DMS_for_Admin_Role.pdf"
      : "User_guide_for_JANA_Marine_DMS_for_User_Role.pdf";
    link.setAttribute("download", downloadFileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDocumentClick = async (documentPath, docUrl) => {
    toggleLoader(true);
    setSelectedDocument(documentPath);
    try {
      const base64Data = await getPdfUrl(docUrl);
      //console.log(base64Data.length);
      if (base64Data === null) {
        //console.error("base64Data is null");
        return;
      }
      const htmlContent = await base64ToHtmlUrl(base64Data.data);
      //console.log(htmlContent, "htmlContent");
      if (htmlContent === null) {
        return;
      }
      setGlobalDocUrl(htmlContent);
      navigate("/Documents");
    } catch (error) {}

    toggleLoader(false);
  };

  const handleCellClick = (cell, formattedData) => {
    const documentPath = cell.row.original.DocumentPath;
    const documentNumber = cell.row.original.DocumentNo;
    const docID = cell.row.original.Id;

    let documentData = formattedData.filter((doc) => doc.Id === docID);
    //console.log(

    if (documentData[0].DocUrl) {
      let docUrl = documentData[0].DocUrl.split("/")
        .pop()
        .replace(/\s/g, "%20");
      setDocData(documentData);
      handleDocumentClick(documentPath, docUrl);
    }
  };

  const base64ToHtmlUrl = async (base64String) => {
    const byteCharacters = atob(base64String);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: "text/html" });
    return URL.createObjectURL(blob);
  };

  const getPdfUrl = async (fileName) => {
    const encodedFileName = encodeURIComponent(fileName);
    const endpoint = `getpdfbyname?pdffileName=${encodedFileName}`;
    //const endpoint = `getpdfbyname?pdffileName=${fileName}`;
    const data = await getApi(endpoint, true);
    if (data.success === false) {
      notify("Error getting pdf", "error");
    }
    return data;
  };

  return (
    <div className="dashboard-container MainDashboard">
      <div className="sub-dashboard-content">
        <div className="TopNav">
          <div className="nav-bar-left">
            <button
              className={`nav-item ${
                activeButton === "lastViewed" ? "active" : ""
              }`}
              onClick={() => handleButtonClick("lastViewed")}
            >
              {`Last Viewed Manuals (${lastViewedCount})`}
            </button>
            <button
              className={`nav-item ${
                activeButton === "newManuals" ? "active" : ""
              }`}
              onClick={() => handleButtonClick("newManuals")}
            >
              {`New Manuals (${NewManualCount})`}
            </button>
            <button
              className={`nav-item ${
                activeButton === "updated" ? "active" : ""
              }`}
              onClick={() => handleButtonClick("updated")}
            >
              {`Updated Manuals (${UpdatedCount})`}
            </button>
          </div>
          <div className="nav-bar-right">
            {isAdmin && (
              <Tooltip
                title="Download Template"
                sx={{
                  "&:hover": { backgroundColor: "#bdbdbd" },
                  backgroundColor: "#bdbdbd",
                  color: "#fff",
                }}
              >
                <IconButton onClick={handleDownloadDocument}>
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip
              title="Download Manual"
              sx={{
                "&:hover": { backgroundColor: "#bdbdbd" },
                backgroundColor: "#bdbdbd",
                color: "#fff",
              }}
            >
              <IconButton onClick={handleDownloadManual}>
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
            <Logout />
          </div>
        </div>
        <div className="SearchBarSection">
          <SearchBar
            setSearchResponse={setSearchResponse}
            setTableData={setTableData}
            setShowSkeleton={setShowSkeleton}
          />
        </div>
        <div className="table-container">
          {showSkeleton ? (
            <div className="skeleton-container">
              <Skeleton height="50vh" />
            </div>
          ) : (
            <Table
              jsonData={tableData.length > 0 ? tableData : FilteredTableData}
              columnFields={columnFields}
              onClickHandler={handleCellClick}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
