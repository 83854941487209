import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import Table from "../../components/ReadAndUnderstand/readAndUnderstandDataTable";
import Table from "../../../../shared/components/Table/Table";
import "./Read.css";
import PageHeader from "../../../../shared/components/pageHeader/PageHeader";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { getApi } from "../../../../util/HttpService";
import { useData } from "../../../../context/dataContext";
import { notify } from "../../../../util/notify";
import { useLoader } from "../../../../context/loaderContext";
import { useDoc } from "../../../../context/docContext";

const ReadandUnderstand = () => {
  const { ownerDepartmentList } = useData();
  const { toggleLoader } = useLoader();
  const [selectedDocument, setSelectedDocument] = useState();
  const navigate = useNavigate();
  const { setDocData, setGlobalDocUrl } = useDoc();

  const [filteredData, setFilteredData] = useState([]);
  const columnFields = [
    { DocumentNo: "Document Number" },
    { DocumentName: "Document Name" },
    { DocumentVersion: "Document Version" },
    { EmployeeID: "Employee ID" },
    { EmployeeName: "Employee Name" },
    { ShipOrShore: "Ship or Shore" },
    { DocumentReadStatus: "Document Read Status" },
    { DocumentPath: "Document Path" },
    { ModifiedOn: "Modified On" },
    { CreatedOn: "Created On" },
    { Owners: "Owners" },
  ];

  useEffect(() => {
    const getDocuments = async () => {
      try {
        const response = await getApi("getReadDocuments", true);
        //console.log("response ", response);
        if (response.success) {
          const data = await response.data;
          //console.log("data ", data);
          filterData(data);
        } else {
          //console.log(`Document fetch failed.`);
        }
      } catch (error) {
        //console.log(`Document fetch failed: ${error}`);
      } finally {
      }
    };
    getDocuments();
  }, []);

  const filterData = (dataArray) => {
    //console.log("dataArray:: ", dataArray)

    let readData = dataArray.map(
      ({
        DocumentNo,
        DocumentName,
        DocumentVersion,
        EmployeeID,
        EmployeeName,
        ShipOrShore,
        DocumentReadStatus,
        DocUrl,
        DocumentPath,
        ModifiedOn,
        CreatedOn,
        OwnerName,
      }) => ({
        DocumentNo,
        DocumentName,
        DocumentVersion,
        EmployeeID,
        EmployeeName,
        ShipOrShore,
        DocumentReadStatus,
        DocUrl,
        DocumentPath,
        ModifiedOn,
        CreatedOn,
        OwnerName,
      })
    );

    setFilteredData(dataArray);
  };

  const handleDocumentClick = async (documentPath, docUrl) => {
    toggleLoader(true);
    setSelectedDocument(documentPath);
    try {
      const base64Data = await getPdfUrl(docUrl);
      //console.log(base64Data.length);
      if (base64Data === null) {
        //console.error("base64Data is null");
        return;
      }
      const htmlContent = await base64ToHtmlUrl(base64Data.data);
      //console.log(htmlContent, "htmlContent");
      if (htmlContent === null) {
        return;
      }
      setGlobalDocUrl(htmlContent);
      navigate("/Documents");
    } catch (error) {}

    toggleLoader(false);
  };

  const handleCellClick = (cell, formattedData) => {
    const documentPath = cell.row.original.DocumentPath;
    const documentNumber = cell.row.original.DocumentNo;
    const docID = cell.row.original.Id;

    let documentData = formattedData.filter((doc) => doc.Id === docID);

    if (documentData[0].DocUrl) {
      let docUrl = documentData[0].DocUrl.split("/")
        .pop()
        .replace(/\s/g, "%20");
      setDocData(documentData);
      handleDocumentClick(documentPath, docUrl);
    }
  };

  const base64ToHtmlUrl = async (base64String) => {
    const byteCharacters = atob(base64String);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: "text/html" });
    return URL.createObjectURL(blob);
  };

  const getPdfUrl = async (fileName) => {
    const encodedFileName = encodeURIComponent(fileName);
    const endpoint = `getpdfbyname?pdffileName=${encodedFileName}`;
    const data = await getApi(endpoint, true);
    if (data.success === false) {
      notify("Error getting pdf", "error");
    }
    return data;
  };

  // const filterData = (dataArray) => {
  //   const updatedDataArray = dataArray.map((document) => ({
  //     ...document,
  //     OwnerName:
  //       ownerDepartmentList.find((owner) => owner.Id === document.OwnerName)
  //         ?.Name || "",
  //   }));
  //   //console.log("updatedDataArray: ", updatedDataArray, "Main data", dataArray);
  //   setFilteredData(updatedDataArray);
  // };
  return (
    <div className="dashboard-container ReadSectionContainer">
      <div className="sub-dashboard-content">
        {/* <h2 className="dashboard-heading-text pointer">Read and Understand</h2> */}
        <PageHeader title="Read and Understand" />
        <div className="table-container">
          {filteredData && filteredData.length > 0 ? (
            <Table
              jsonData={filteredData}
              columnFields={columnFields}
              onClickHandler={handleCellClick}
            />
          ) : (
            <Skeleton height="50vh" />
          )}
        </div>
      </div>
    </div>
  );
};

export default ReadandUnderstand;
