import React, { createContext, useContext, useState } from "react";
import { useData } from "./dataContext";

const DmsContext = createContext();

export const useDms = () => useContext(DmsContext);

export const DmsDocs = ({ children }) => {
  const [docSubTitle, setDocSubTitle] = useState("Manuals");
  const [categoryName, setCategoryName] = useState("");
  const [docInfo, setDocInfo] = useState([]);
  const [formInfo, setFormInfo] = useState([]);
  const [formData, setFormData] = useState([]);
  const [sidebarData, setSidebarData] = useState([]);
  //const { setDocumentData } = useData();

  const filterData = (element, data) => {
    const filtered = data.filter((doc) => doc.DocumentPath?.includes(element));
    //console.log("🚀 ~ filterData ~ filtered before sorting:", filtered);

    if (filtered.length > 0) {
      filtered.sort((a, b) => a.DocumentNo.localeCompare(b.DocumentNo));
      //console.log("filtered data after sorting", filtered);
      setDocInfo(filtered);
    } else {
      setDocInfo([]);
    }
  };

  const filterForms = (element, data) => {
    const filtered = data.filter((form) => form.FormPath?.includes(element));
    if (filtered.length > 0) {
      filtered.sort((a, b) => a.FormNo.localeCompare(b.FormNo));
      setFormInfo(filtered);
      //console.log(

    } else {
      setFormInfo([]);
    }
  };
  const updateSidebarData = (categoryResponse, docResponse, formResponse) => {
    //console.log("data in setSider  ", formResponse);
    filterData(categoryName, docResponse);
    filterForms(categoryName, formResponse);
    const sidebarItems = categoryResponse.map((item) => ({
      title: item.ElementName,
      content: [
        {
          title: "Manuals",
          onClick: () => {
            setDocSubTitle("Manuals");
            setCategoryName(item.ElementName);
            filterData(item.ElementName, docResponse);
          },
        },
        {
          title: "Forms",
          onClick: () => {
            setDocSubTitle("Forms");
            setCategoryName(item.ElementName);
            filterForms(item.ElementName, formResponse);
          },
        },
      ],
      onClick: () => {
        setCategoryName(item.ElementName);
        setDocSubTitle("Manuals");
        filterData(item.ElementName, docResponse);
      },
    }));

    setFormData(formResponse);
    //setDocumentData(docResponse);

    setSidebarData(sidebarItems);
  };
  const setDefaultCategory = (elementListData, docResponse) => {
    setCategoryName(elementListData[0].ElementName);
    filterData(elementListData[0].ElementName, docResponse);
  };
  return (
    <DmsContext.Provider
      value={{
        setCategoryName,
        docSubTitle,
        setDocSubTitle,
        setDocInfo,
        formInfo,
        setFormInfo,
        categoryName,
        docInfo,
        formData,
        sidebarData,
        setFormData,
        setSidebarData,
        updateSidebarData,
        filterData,
        setCategoryName,
        setDefaultCategory,
      }}
    >
      {children}
    </DmsContext.Provider>
  );
};
