import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./DMS_dashboard.css";
import PageHeader from "../../components/pageHeader/PageHeader";
import SidebarAccordion from "../../components/Accordian/Accordian";
import { useDms } from "../../../context/dmsContext";
import Table from "../../components/Table/Table";
import { useAuth } from "../../../context/authContext";
import { getApi } from "../../../util/HttpService";
import { useData } from "../../../context/dataContext";
import { useLoader } from "../../../context/loaderContext";
import { useDoc } from "../../../context/docContext";
import { notify } from "../../../util/notify";
import FormTable from "../../components/FormTable/FormTable";
import { SECTION_TYPE_GRANULARITY } from "@mui/x-date-pickers/internals/utils/getDefaultReferenceDate";

const DMS_Dashboard = () => {
  const { docInfo, docSubTitle, categoryName, formInfo } = useDms();
  const [tableData, setTableData] = useState([]);

  const { ownerDepartmentList } = useData();
  const { toggleLoader } = useLoader();
  const { setDocData, setGlobalDocUrl } = useDoc();
  const [selectedDocument, setSelectedDocument] = useState();
  const navigate = useNavigate();

  // useEffect(() => {
  //   const loadData = async () => {
  //     toggleLoader(true);
  //     //console.log("docSubTitle: ", docSubTitle);
  //     // setTableData(docSubTitle == "Manuals" ? docInfo : formInfo);

  //     if (docSubTitle === "Manuals") {
  //       if (docInfo) {
  //         setTableData(
  //           docInfo.map((document) => ({
  //             ...document,
  //             OwnerDepartment: ownerDepartmentList.find(
  //               (dept) => dept.Id == document.OwnerDepartment
  //             )
  //               ? ownerDepartmentList.find(
  //                   (dept) => dept.Id == document.OwnerDepartment
  //                 ).Name
  //               : "",
  //           }))
  //         );
  //       }
  //     } else {
  //       if (formInfo) {
  //         setTableData(
  //           formInfo.map((form) => ({
  //             ...form,
  //             OwnerDepartment: ownerDepartmentList.find(
  //               (dept) => dept.Id == form.OwnerDepartment
  //             )
  //               ? ownerDepartmentList.find(
  //                   (dept) => dept.Id == form.OwnerDepartment
  //                 ).Name
  //               : "",
  //           }))
  //         );
  //       }
  //     }
  //     toggleLoader(false);
  //   };

  //   loadData();
  // }, [docInfo,formInfo]);

  const columnFields = [
    { DocumentNo: "Document Number" },
    { DocumentName: "Document Name" },
    { DocumentPath: "Document Path" },
    { DocumentVersion: "Document Version" },
    { CreatedOn: "Created On" },
    { ModifiedOn: "Modified On" },
    { UpdatedByName: "Modified By" },
    { OwnerName: "Owners" },
  ];

  const formFields = [
    { FormNo: "Form Number" },
    { FormName: "Form Name" },
    { FormPath: "Form Path" },
    { FormVersion: "Form Version" },
    { CreatedOn: "Created On" },
    // { ModifiedOn: "Modified On" },
    { UploadedByName: "Uploaded By" },
    { OwnerName: "Owners" },
  ];

  const Prefixes = {
    docx: "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    xls: "application/vnd.ms-excel",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  };

  const handleDocumentClick = async (documentPath, docUrl) => {
    toggleLoader(true);
    setSelectedDocument(documentPath);
    try {
      const base64Data = await getPdfUrl(docUrl);
      //console.log(base64Data.length);
      if (base64Data === null) {
        //console.error("base64Data is null");
        return;
      }
      const htmlContent = await base64ToHtmlUrl(base64Data.data);
      //console.log(htmlContent, "htmlContent");
      if (htmlContent === null) {
        return;
      }
      setGlobalDocUrl(htmlContent);
      navigate("/Documents");
    } catch (error) {}

    toggleLoader(false);
  };

  const handleCellClick = (cell, formattedData) => {
    //console.log("formatted data",formattedData);
    //console.log("cell: ",cell);
    const documentPath = cell.row.original.DocumentPath;
    const documentNumber = cell.row.original.DocumentNo;
    const docID = cell.row.original.Id;

    const formData = formattedData.filter(
      (doc) => doc.FormNo === cell.row.original.FormNo
    );

    if (formData[0].FormUrl) {
      let formUrl = formData[0].FormUrl;
      let formName = formData[0].FormName;
      // //console.log("Form URl: ", formUrl, " Form Name: ", formData[0].FormName);
      syncGetFormData(formUrl, formName);
    }
    let documentData = formattedData.filter((doc) => doc.Id === docID);

    if (documentData[0].DocUrl) {
      let docUrl = documentData[0].DocUrl.split("/")
        .pop()
        .replace(/\s/g, "%20");
      setDocData(documentData);
      handleDocumentClick(documentPath, docUrl);
    }
  };

  const syncGetFormData = async (formUrl, formName) => {
    try {
      //console.log("form name",formName);
      const formBase64Data = await getFormData(formUrl);

      const nameSplitArray = formUrl.split(".");
      const extension = nameSplitArray[nameSplitArray.length - 1];

      downloadBase64AsDocx(formBase64Data, formName, extension);
    } catch (error) {
      console.error(`Error getting forFm data for: ${formUrl}`, error);
    }
  };

  const getFormData = async (formUrl) => {
    const endpoint = `forms/download?formUrl=${formUrl}`;
    const data = await getApi(endpoint, true);
    //console.log("Form data:  recived ", data);
    return data.data;
  };

  const downloadBase64AsDocx = (base64String, fileName, extension) => {
    const prefix = Prefixes[extension.toLowerCase()];
    const linkSource = `data:${prefix};base64,${base64String}`;
    const downloadLink = document.createElement("a");
    const fileNameWithExtension = `${fileName}.${extension}`;
    downloadLink.setAttribute("href", linkSource);
    downloadLink.setAttribute("download", fileNameWithExtension);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const getPdfUrl = async (fileName) => {
    //const endpoint = `getpdfbyname?pdffileName=${fileName}`;
    const encodedFileName = encodeURIComponent(fileName);
    const endpoint = `getpdfbyname?pdffileName=${encodedFileName}`;

    const data = await getApi(endpoint, true);
    if (data.success === false) {
      notify("Error getting pdf", "error");
    }
    return data;
  };

  const base64ToHtmlUrl = async (base64String) => {
    const byteCharacters = atob(base64String);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: "text/html" });
    return URL.createObjectURL(blob);
  };

  return (
    <div className="dms_dashboard">
      <div className="dms_dashboard_table">
        <div className="headBlock">
          <PageHeader title="Document Management System" />
          <h6 className="subTitle">{docSubTitle}</h6>
          <p>{categoryName}</p>
        </div>

        <div className="tableBlock">
          {docSubTitle === "Manuals" && tableData && (
            <Table
              key={"Manuals"}
              jsonData={docInfo}
              columnFields={columnFields}
              onClickHandler={handleCellClick}
            />
          )}
          {docSubTitle === "Forms" && formInfo && (
            // <Table
            //   key={"Forms"}
            //   jsonData={formInfo}
            //   columnFields={formFields}
            //   onClickHandler={handleCellClick}
            // />
            <FormTable
              key={"Forms"}
              jsonData={formInfo}
              columnFields={formFields}
              onDownloadClick={handleCellClick}
              setTableData={setTableData}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DMS_Dashboard;
