import React, { useMemo } from "react";
import { MenuItem } from "@mui/material";
import {
  useMaterialReactTable,
  MaterialReactTable,
} from "material-react-table";
import { useAuth } from "../../../context/authContext";
import { deleteApi } from "../../../util/HttpService";
import { notify } from "../../../util/notify";
import { useDms } from "../../../context/dmsContext";

import { useData } from "../../../context/dataContext";
import { pageSize } from "../../../constants/constant";
const FormTable = ({
  jsonData,
  columnFields,
  setTableData,
  onDownloadClick,
}) => {
  const { isAdmin } = useAuth();
  const { setFormInfo, setFormData, formData, updateSidebarData } = useDms();
  const {
    setOwnerDepartmentList,
    elementListData,
    setElementListData,
    setDocumentData,
    documentData,
  } = useData();
  const formatDate = (dateString, key) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    if (key === "LoginTime") {
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    }
    return `${day}/${month}/${year}`;
  };

  const formattedData = useMemo(() => {
    return jsonData.map((item) => {
      const newItem = { ...item };
      ["CreatedOn", "ModifiedOn", "LoginTime"].forEach((key) => {
        if (newItem[key]) {
          newItem[key] = formatDate(newItem[key], key);
        }
      });
      return newItem;
    });
  }, [jsonData]);

  const columns = useMemo(() => {
    if (!jsonData[0]) return [];

    const firstItem = jsonData[0];
    const columnFieldLookup = columnFields.reduce((acc, field) => {
      const key = Object.keys(field)[0];
      acc[key] = field[key];
      return acc;
    }, {});

    return Object.keys(firstItem).flatMap((key) => {
      if (!columnFieldLookup[key]) return [];

      if (typeof firstItem[key] === "object" && firstItem[key] !== null) {
        return columnFields.map((field) => {
          const key = Object.keys(field)[0];
          return {
            accessorKey: `${key}`,
            header: field[key],
            size: 150,
          };
        });
      }

      return [
        {
          accessorKey: key,
          header: columnFieldLookup[key],
          size: 150,
        },
      ];
    });
  }, [jsonData, columnFields]);

  const handleDeleteDoc = async (docId) => {
    try {
      if (!docId) {
        notify("Document deletion failed: Invalid document ID", "error");
        return;
      }

      const newData = formData.filter((item) => item.Id !== docId);
      //setFormData((prevData) => prevData.filter((item) => item.Id !== docId));
      updateSidebarData(elementListData, documentData, newData);
      setFormInfo((prevData) => prevData.filter((item) => item.Id !== docId));
      const response = await deleteApi(`forms/${docId}`, true);

      if (!response.result) {
        notify(
          "Document deletion failed, please refresh the page and try again",
          "error"
        );
        return;
      }
    } catch (error) {
      console.error("Delete document error:", error);
      notify("Document deletion failed", "error");
    }
  };

  const data = useMemo(() => [...formattedData], [formattedData]);

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: { pagination: pageSize },

    initialState: { columnVisibility: { Id: false } },
    muiCircularProgressProps: {
      color: "secondary",
      thickness: 5,
      size: 55,
    },
    muiSkeletonProps: {
      animation: "pulse",
      height: 55,
    },
    enablePagination: true,
    paginationDisplayMode: "pages",
    enableStickyHeader: true,
    muiTableContainerProps: {
      sx: { maxHeight: "50vh", overflow: "auto", minHeight: "45vh" },
    },

    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        backgroundColor: row.index % 2 === 0 ? "#4bade00a" : "#fff",
      },
    }),
    renderRowActionMenuItems: ({ row, closeMenu }) =>
      [
        <MenuItem
          key="download"
          onClick={() => {
            // Create a cell-like object to match the expected structure
            const cell = {
              row: {
                original: row.original,
              },
            };
            onDownloadClick(cell, formattedData);
            closeMenu();
          }}
        >
          Download
        </MenuItem>,
        isAdmin && (
          <MenuItem
            key="delete"
            onClick={async () => {
              await handleDeleteDoc(row.original.Id);
              closeMenu();
            }}
            sx={{ color: "red" }}
          >
            Delete
          </MenuItem>
        ),
      ].filter(Boolean),
    muiTableBodyCellProps: {
      sx: {
        "& .MuiIconButton-root": {
          transform: "rotate(90deg)",
        },
      },
    },
    initialState: { pagination: pageSize },

    enableStickyHeader: true,
    enableColumnResizing: true,
    enableFiltering: true,
    enableColumnFilters: true,
    enablePagination: true,
    paginationDisplayMode: "pages",
    positionActionsColumn: "last",
    enableRowActions: true,
  });

  return (
    <div className="tableContainer">
      {formattedData?.length > 0 ? (
        <MaterialReactTable table={table} />
      ) : (
        <p className="text-center p-4 text-gray-500">No data available</p>
      )}
    </div>
  );
};

export default FormTable;
