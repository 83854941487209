import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { getApi } from "../util/HttpService";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState(null);
  const [isMounted,setIsMounted] = useState(false);

  const checkAuthStatus = useCallback(async () => {
    
    setIsLoading(true);
    const tokenFromLocalStorage = localStorage.getItem("token");
    const adminCheckLocalStorage = localStorage.getItem("isAdmin");

    if (!token && tokenFromLocalStorage) {
      setToken(tokenFromLocalStorage);
    }

    if (isAdmin === null) {
      setIsAdmin(adminCheckLocalStorage === "true");
    }

    if (tokenFromLocalStorage) {
      try {
        const result = await getApi("me", true);
        if (result.data && result.data[0]) {
          const userData = result.data[0];
          setUserDetails(userData);
          setIsAdmin(userData.IsAdmin);
          setUserId(userData.userId);
          setUserName(userData.FullName);
          setIsAuthenticated(true);
          setIsLoggedIn(true);
        } else {
        }
      } catch (error) {
        console.error("Error checking auth status:", error);
        setIsAuthenticated(false);
        setIsLoggedIn(false);
        setToken(null);
      }
    } else {
      setIsAuthenticated(false);
      setIsLoggedIn(false);
    }
    setIsLoading(false);
  }, [token, isAdmin]);

  useEffect(()=>{

    setIsMounted(true);

    return ()=>{
      setIsMounted(false);
    }
  },[]);

  useEffect(() => {
   
    if(isMounted){
      checkAuthStatus();
    }
  }, [isMounted]);

  const logout = useCallback(() => {
    setIsAuthenticated(false);
    setIsLoggedIn(false);
    setToken(null);
    setUserDetails(null);
    setUserId(null);
    setUserName(null);
    setIsAdmin(false);
    localStorage.clear();
  }, []);

  const value = {
    isAuthenticated,
    setIsAuthenticated,
    userId,
    setUserId,
    userName,
    setUserName,
    isAdmin,
    setIsAdmin,
    setToken,
    token,
    isLoggedIn,
    setIsLoggedIn,
    checkAuthStatus,
    isLoading,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
