import React, { useEffect, useState, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { navItems } from "../../../constants/navData/NavData";
import { useAuth } from "../../../context/authContext";
import { useDms } from "../../../context/dmsContext";
import { getApi } from "../../../util/HttpService";
import SidebarAccordion from "../Accordian/Accordian";
import logo from "../../../Assets/Images/logo.png";
import { useData } from "../../../context/dataContext";
import "./sideNav.css";
import Loader from "../loader/loader";
import { useLoader } from "../../../context/loaderContext";

const SideNav = () => {
  const { isAdmin } = useAuth();
  const {
    setOwnerDepartmentList,
    elementListData,
    setElementListData,
    setDocumentData,
  } = useData();
  const location = useLocation();
  const { toggleLoader } = useLoader();

  const currentLocation = location.pathname;
  const {
    setCategoryName,
    setDocSubTitle,
    setDocInfo,
    docSubTitle,
    ElementName,
    formInfo,
    setFormInfo,
    formData,
    setFormData,
    sidebarData,
    setSidebarData,
    setDefaultCategory,
    updateSidebarData,
  } = useDms();

  const items = isAdmin ? navItems.admin : navItems.user;

  const [isMounted, setIsMounted] = useState(false);
  // //console.log("side items",items);

  async function fetchForms() {
    try {
      const response = await getApi("forms", true);
      if (response.success) {
        const data = response.data;
        // setFormData(data);
        //console.log("Data from the Forms API is: ", data);
        return data;
      } else {
        console.error("Failed to fetch forms:", await response.json());
      }
      //console.log("Filter data for Form: ", ElementName, formData);
    } catch (error) {
      console.error("Error fetching forms:", error);
    }
  }

  async function fetchCategories() {
    try {
      const response = await getApi("elementlist", true);
      if (response.success) {
        const data = response.data;
        return data;
      } else {
        console.error("Failed to fetch categories:", await response.json());
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  }

  async function fetchOwnerDepartments() {
    try {
      const response = await getApi("ownerDepartment", true);
      if (response.success) {
        const data = response.data;
        //console.log("Data from the Owner Department API is: ", data);
        // setOwnerDepartmentList(data);
        return data;
      } else {
        console.error(
          "Failed to fetch owner departments:",
          await response.json()
        );
      }
    } catch (error) {
      console.error("Error fetching owner departments:", error);
    }
  }

  async function fetchDocuments() {
    try {
      const response = await getApi("documentlist", true);
      if (response.success) {
        const actualData = response.data.data;

        return actualData;
      } else {
        console.error("Failed to fetch documents:", response.error);
      }
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  }

  const getAllData = async () => {
    const formResponse = await fetchForms();
    const docResponse = await fetchDocuments();
    const categoryResponse = await fetchCategories();
    const ownerDepartmentResponse = await fetchOwnerDepartments();

    // const [
    //   formResponse,
    //   docResponse,
    //   categoryResponse,
    //   ownerDepartmentResponse,
    // ] = await Promise.all([
    //   fetchForms,
    //   fetchDocuments,
    //   fetchCategories,
    //   fetchOwnerDepartments,
    // ]);

    //after fetching all data, set initial states
    //console.log("Form response", formResponse);
    //console.log("category response: ", categoryResponse);
    //console.log("op data: ", ownerDepartmentResponse);
    const tempForm = formResponse.map((item) => ({
      ...item,
      OwnerName: ownerDepartmentResponse.find(
        (o) => o.Id === item.OwnerDepartment
      )?.Name,
    }));
    const tempDocs = docResponse.map((item) => ({
      ...item,
      OwnerName: ownerDepartmentResponse.find(
        (o) => o.Id === item.OwnerDepartment
      )?.Name,
    }));
    //console.log("temp: ", tempForm, tempDocs);
    setFormData(tempForm);
    setDocumentData(tempDocs);
    setOwnerDepartmentList(ownerDepartmentResponse);
    setElementListData(categoryResponse);

    updateSidebarData(categoryResponse, tempDocs, tempForm);
    setDefaultCategory(categoryResponse, tempDocs);
  };

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);
  useEffect(() => {
    isMounted && getAllData();
    // fetchOwnerDepartments();
  }, [isMounted]);

  const handleClickDownload = async () => {
    try {
      toggleLoader(true);
      //console.log("TRY...");
      const response = await getApi("export/export", true);
      //console.log("response ", response);

      // Assuming the base64-encoded file is inside `response.file`
      const base64String = response.data;
      //console.log("Base64 response: ", base64String);

      if (!base64String) {
        throw new Error("Base64 string is missing from the response.");
      }
      //console.log("Base64 response: ", base64String);

      // Strip any header from the base64 string if necessary (e.g., "data:application/zip;base64,")
      const base64Data = base64String.split(",").pop(); // Get only the base64 part

      // Decode base64 string to binary data
      const binaryString = atob(base64Data);
      const byteArray = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }

      // Create a Blob from the byte array
      const blob = new Blob([byteArray], { type: "application/zip" });

      // Debug: Check blob size and type
      //console.log("Blob created: ", blob);
      //console.log("Blob size: ", blob.size);
      //console.log("Blob type: ", blob.type);

      // Create a URL for the Blob
      const blobUrl = URL.createObjectURL(blob);

      // Create an anchor element to trigger the download
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = "exported_file.zip"; // Set the desired filename

      // Simulate a click on the link to trigger the download
      link.click();

      // Clean up the URL object after download
      URL.revokeObjectURL(blobUrl);

      //console.log("Download initiated successfully.");
      toggleLoader(false);
    } catch (error) {
      console.error(`Document fetch failed: ${error}`);
      toggleLoader(false);
    }
  };

  return (
    <div className="side-nav-container">
      <div className="side-nav-logo">
        <img src={logo} alt="logo" />
      </div>
      <ul className="side-nav-list">
        {items.map((item, index) => {
          const isActive = item.path === currentLocation;
          const itemClassName = isActive ? "activeItem" : "";

          return (
            <li
              className={`side-nav-item ${
                item.children ? "navItemChildren" : ""
              }`}
              key={index}
            >
              {item.title == "Export Package" ? (
                <p
                  className={`${itemClassName} download-vessel`}
                  onClick={handleClickDownload}
                >
                  <item.icon />
                  <p className="side-nav-text">{item.title}</p>
                </p>
              ) : (
                <Link
                  className={`${itemClassName} side-nav-link`}
                  to={item.path}
                >
                  <item.icon />
                  <p className="side-nav-text">{item.title}</p>
                </Link>
              )}
              {isActive && item.children && elementListData && (
                <SidebarAccordion
                  className="side-nav-accordian"
                  sidebarItems={sidebarData}
                />
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SideNav;
