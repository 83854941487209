import React, { useEffect, useState } from "react";
import Table from "../../../../shared/components/Table/Table";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import "./LoginHistory.css";
import PageHeader from "../../../../shared/components/pageHeader/PageHeader";
import { getApi } from "../../../../util/HttpService";
const LoginHistory = () => {

  const [loginHistoryData, setloginHistoryData] = useState([]);
  const columnFields = [
    { EmployeeID: "Employee ID" },
    { EmployeeName: "Employee Name" },
    { Device: "Device" },
    { LoginTime: "Login Time" },
    { IPAddress: "IP Address" },
    { status: "Status" }
  ];
  useEffect(() => {

    const getLoginHistory = async () => {
      try {
        const response = await getApi('getLoginHistory', true);

        if (response.success) {
          const dataArray = response.data;
          let filteredData = dataArray.map(({
            EmployeeID,
            EmployeeName,
            Device,
            LoginTime,
            IPAddress,
            status

          }) => ({
            EmployeeID,
            EmployeeName,
            Device,
            LoginTime,
            IPAddress,
            status

          }));
          //console.log("data without id: ", filteredData)
          setloginHistoryData(filteredData);
        } else {
          const errorData = await response.json();
          //console.log(`Document fetch failed: ${errorData.error}`);
        }
      } catch (error) {
        //console.log(`Document fetch failed: ${error}`);
      } finally {
      }
    };
    getLoginHistory();

  }, []);

  const handleCellClick = (cell, formattedData) =>{
  }

  return (
    <div className="dashboard-container">
      <div className="sub-dashboard-content">
        <PageHeader title="Login History" />

        <div className="login-dashboard table-container">
          {loginHistoryData && loginHistoryData.length > 0 ? (

            <Table jsonData={loginHistoryData} columnFields={columnFields} onClickHandler={handleCellClick}/>
          ) : (
            <Skeleton height="50vh" />
          )}
        </div>
      </div>
    </div>
  );

};

export default LoginHistory;