import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./generalDocs.css";
import PageHeader from "../../../../shared/components/pageHeader/PageHeader";
import AddIcon from "@mui/icons-material/Add";
import { Box, Fade, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getApi } from "../../../../util/HttpService";
import Table from "../../../../shared/components/ActionTable/ActionTable";
import DocTable from "../../components/DocTable/DocTable";
import AddGenDoc from "../../components/addDocument/addGenDoc";
import { useAuth } from "../../../../context/authContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  height: "auto",
  borderRadius: "42px",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

const GeneralDocs = () => {
  const [filteredData, setFilteredData] = useState([]);
  //console.log("filtered data: ", filteredData);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const { userId, isAdmin } = useAuth();

  const [showSkeleton, setShowSkeleton] = useState(true);
  const navigate = useNavigate();
  const columnFields = [{ DocumentName: "Document Name" }];

  useEffect(() => {
    getDocs();
  }, []);

  const getDocs = async () => {
    try {
      setShowSkeleton(true);
      const response = await getApi("generalDocuments", true);

      if (response.success) {
        // const data = await response.json();
        const dataArray = response.data;
        setFilteredData(dataArray);
      } else {
        //console.log(`Document fetch failed`);
      }
    } catch (error) {
      //console.log(`Document fetch failed: ${error}`);
    } finally {
    }
    setShowSkeleton(false);
  };

  const handleClickAddRole = () => {
    setOpen(true);
    // //console.log("Add Role");
    // navigate('/add-role')
  };

  return (
    <div className="role-dashboard dashboard-container">
      <PageHeader title="General documents" />
      <div className="sub-dashboard-content">
        <div className="header-container">
          {isAdmin && (
            <button className="add-role-btn" onClick={handleClickAddRole}>
              <AddIcon />
              Add documents
            </button>
          )}
        </div>
        <div className="table-container">
          {showSkeleton ? (
            <Skeleton height="50vh" />
          ) : (
            <DocTable
              jsonData={filteredData}
              columnFields={columnFields}
              setTableData={setFilteredData}
            />
          )}
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={style}>
          <AddGenDoc setOpen={setOpen} setTableData={setFilteredData} />
        </Box>
      </Modal>
    </div>
  );
};

export default GeneralDocs;
