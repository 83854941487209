import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import PageHeader from "../../../../shared/components/pageHeader/PageHeader";
import { useAuth } from "../../../../context/authContext";
import { notify } from "../../../../util/notify";
import {
  addFormDocumentData,
  getApi,
  postApi,
  uploadFormData,
} from "../../../../util/HttpService";
import { useData } from "../../../../context/dataContext";
import { useNavigate } from "react-router-dom";
import { useDms } from "../../../../context/dmsContext";

const AddForms = () => {
  const navigate = useNavigate();
  const { userId, userName } = useAuth();
  const {
    ownerDepartmentList,
    elementListData,
    documentData: docData,
  } = useData();
  const { setFormInfo, setFormData, formData, updateSidebarData } = useDms();

  const addForm = async (values) => {
    const endpoint = "forms";
    try {
      const data = await addFormDocumentData(endpoint, values, true);
      //console.log("data: in add form", data);
      return data;
    } catch (error) {
      console.error("Error adding form:", error);
      throw error;
    }
  };
  const handleUpload = async (file) => {
    if (!file) {
      throw new Error("Please select a file to upload");
    }
    try {
      const response = await uploadFormData("forms/upload", file, true);
      //console.log(response);
      if (!response.success) {
        throw new Error(response.error || "File upload failed");
      }
      return response;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  const formik = useFormik({
    initialValues: {
      formName: "",
      formNo: "",
      Version: "",
      // ownerName: '',
      ownerDepartment: "",
      categoryId: "",
      file: null,
    },
    validationSchema: Yup.object({
      formName: Yup.string()
        .required("Form name is required")
        .max(150, "Form name should not exceed 150 characters")
        .test("is-not-only-space", "Kindly enter valid value", (value) => {
          return value.trim().length > 0;
        }),
      formNo: Yup.string()
        .required("Form number is required")
        .max(20, "Form number should not exceed 50 characters")
        .test("is-not-only-space", "Kindly enter valid value", (value) => {
          return value.trim().length > 0;
        }),
      Version: Yup.number()
        .required("Version number is required")
        .test("is-decimal", "Invalid version number", (value) => {
          return /^\d*\.?\d+$/.test(value.toString());
        })
        .max(100.0, "Version number should not exceed 100.0"),
      // ownerName: Yup.string()
      //     .required("Owner name is required")
      //     .max(50, "Owner name should not exceed 50 characters"),
      ownerDepartment: Yup.string()
        .required("Owner is required")
        .max(50, "Owner should not exceed 50 characters"),
      categoryId: Yup.string().required("Element is required"),
      file: Yup.mixed()
        .required("File is required")
        .test(
          "is-docx",
          "Document should be in docx,xls or xlsx format",
          (value) => {
            //console.log("Value type", value.type);
            return (
              value &&
              (value.type ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                value.type === "application/vnd.ms-excel" ||
                value.type ===
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
            );
          }
        )
        .test("file-size", "File size should not exceed 60MB", (value) => {
          return value && value.size <= 60 * 1024 * 1024;
        }),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const uploadResponse = await handleUpload(values.file);
        let documentData = {
          formName: values.formName,
          formVersion: values.Version,
          formPath: elementListData.find(
            (category) => category.Id == values.categoryId
          ).ElementName,
          // ownerName: values.ownerName,
          ownerDepartment: values.ownerDepartment,
          elementId: values.categoryId,
          formUrl: uploadResponse.data.blobUrl,
          formNo: values.formNo,
        };
        //console.log("documentData: ", documentData);
        const response = await addForm(documentData);
        if (response.success) {
          notify("Form added successfully", "success");
          //console.log("response.data.data: ", response.data);
          const newFormJson = {
            FormNo: values.formNo,
            FormName: values.formName,
            FormVersion: values.Version,
            FormPath: elementListData.find(
              (category) => category.Id == values.categoryId
            ).ElementName,
            // ownerName: values.ownerName,
            OwnerDepartment: values.ownerDepartment,
            ElementId: values.categoryId,
            FormUrl: uploadResponse.data.blobUrl,
            CreatedOn: new Date().toISOString(),
            ModifiedOn: new Date().toISOString(),
            Id: response.data.Id,
            UploadedBy: userId,
            UploadedByName: userName,
            IsActive: true,
            OwnerName: ownerDepartmentList.find(
              (dept) => dept.Id == values.ownerDepartment
            ).Name,
          };
          //console.log("newFormJson: ", newFormJson);
          const newData = [...formData, newFormJson];
          updateSidebarData(elementListData, docData, newData);

          resetForm();
          setSubmitting(false);
        } else {
          notify(response.error, "error");

          setSubmitting(false);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        notify("Error submitting form", "error");
        setSubmitting(false);
      }
    },
  });

  return (
    <div className="docUpload">
      <PageHeader title="Upload Form" />
      <form onSubmit={formik.handleSubmit} className="formContainer">
        <div className="form">
          <div className="formInputs">
            <div className="formSubContainer">
              <div className="DisplayName">
                <p className="label">Form Display Name</p>
                <input
                  type="text"
                  id="formName"
                  name="formName"
                  placeholder="Enter Form Display Name"
                  className="formInputsText"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.formName}
                />
                {formik.touched.formName && formik.errors.formName ? (
                  <div className="error">{formik.errors.formName}</div>
                ) : null}
              </div>
              <div className="FormNumber">
                <p className="label">Form Number</p>
                <input
                  type="text"
                  id="formNo"
                  name="formNo"
                  placeholder="Enter Form Number"
                  className="formInputsText"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.formNo}
                />
                {formik.touched.formNo && formik.errors.formNo ? (
                  <div className="error">{formik.errors.formNo}</div>
                ) : null}
              </div>
            </div>
            <div className="formSubContainer">
              <div className="Version">
                <p className="label">Version Number</p>
                <input
                  type="text"
                  id="Version"
                  name="Version"
                  placeholder="Enter Version Number"
                  className="formInputsText"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.Version}
                />
                {formik.touched.Version && formik.errors.Version ? (
                  <div className="error">{formik.errors.Version}</div>
                ) : null}
              </div>
              {/* <div className="OwnerName">
                                <p className="label">Owner name</p>
                                <input
                                    type="text"
                                    id="ownerName"
                                    name="ownerName"
                                    placeholder="Enter owner name"
                                    className="formInputsText"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.ownerName}
                                />
                                {formik.touched.ownerName && formik.errors.ownerName ? (
                                    <div className="error">{formik.errors.ownerName}</div>
                                ) : null}
                            </div> */}
              <div className="OwnerDepartment">
                <p className="label">Owners</p>
                <select
                  id="ownerDepartment"
                  name="ownerDepartment"
                  placeholder="Select Department"
                  className="formInputsDrpDown"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ownerDepartment}
                >
                  <option value="">Select</option>
                  {ownerDepartmentList.map((department) => (
                    <option key={department.Id} value={department.Id}>
                      {department.Name}
                    </option>
                  ))}
                </select>
                {formik.touched.ownerDepartment &&
                formik.errors.ownerDepartment ? (
                  <div className="error">{formik.errors.ownerDepartment}</div>
                ) : null}
              </div>
            </div>
            <div className="formSubContainer">
              <div className="Category">
                <p className="label">SMS Element</p>
                <select
                  id="categoryId"
                  name="categoryId"
                  placeholder="Select Element"
                  className="formInputsDrpDown"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.categoryId}
                >
                  <option value="">Select Element</option>
                  {elementListData.map((category) => (
                    <option value={category.Id} key={category.Id}>
                      {category.ElementName}
                    </option>
                  ))}
                </select>
                {formik.touched.categoryId && formik.errors.categoryId ? (
                  <div className="error">{formik.errors.categoryId}</div>
                ) : null}
              </div>
              <div className="DocUpload">
                <p className="label">Form Upload</p>
                <input
                  type="file"
                  // id="file"
                  name="file"
                  id="fileInput"
                  key={formik.values.categoryId}
                  className="file-input"
                  accept=".docx,.xls,.xlsx"
                  multiple={false}
                  placeholder="Select File"
                  onChange={(event) => {
                    const selectedFile = event.currentTarget.files[0];
                    formik.setFieldValue("file", selectedFile);
                    //console.log("formik file value is ", selectedFile);
                  }}
                />
                {formik.touched.file && formik.errors.file ? (
                  <div className="error">{formik.errors.file}</div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="BtnContainer">
          <button
            type="button"
            className="ActionButtons"
            onClick={() => navigate(-1)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ActionButtons activeBtn"
            disabled={formik.isSubmitting || formik.isValidating}
          >
            {formik.isSubmitting ? "Saving..." : "Save"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddForms;
