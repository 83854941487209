export const customCSS = `
    body {
      font-family: Calibri, sans-serif;
      overflow:auto;
      -webkit-print-color-adjust: exact;color-adjust: exact;

    }
    table tr:first-child {
      background-color: #d9e2f3 !important;
    }
    tbody tr:first-child {
      background-color: #d9e2f3 !important;
    }
    table tr td{
       min-width:55px;
    }
      img{
      max-width:100%;
      }
      .editor-content table {
  border: 1px solid black;
  border-collapse: collapse;
  padding-block: 0.5rem;
      width: 100%;
}


.editor-content td,
.editor-content th {
  border: 1px solid black;
  padding: 0.5rem 0.5rem !important;
  width: max-content;
}
.editor-content table tr:first-child {
      background-color: #d9e2f3 !important;

}
.editor-content {
  border: 1px solid #ccc;
  min-height: 200px;
  padding: 10px;
  margin-top: 10px;
  position: relative;
}

.editor-content:focus-visible,
.editor-content:active,
.editor-content:hover,
.editor-content:focus-within {
  outline: 1px solid #ccc;
}

h3
{
font-size: 12pt; font-weight:bold;
font-family: Calibri, sans-serif;
}


h2,h1
{
font-size: 12pt; font-weight:bold;
font-family: Calibri, sans-serif;}

h4,h5,h6
{
font-size: 12pt; font-weight:bold;
font-family: Calibri, sans-serif;
}


p
{
font-size: 11pt;
padding-block: 0.5rem !important;
font-family: Calibri, sans-serif;
margin:0;

}

li
{
padding-block: 0.5rem !important;
font-family: Calibri, sans-serif;
font-size: 11pt;
}
 
  `;
