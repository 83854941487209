import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { putApi } from '../../../../util/HttpService';
import { notify } from '../../../../util/notify';
import { toast } from 'react-toastify';
import { useData } from '../../../../context/dataContext';
import { useDms } from '../../../../context/dmsContext';

const UpdateCategory = ({ setModalOpen, editData, setTableData }) => {
  const location = useLocation();
  const value = location.state || {};

  const [categoryId, setCategoryId] = useState(editData.Id);
  const [inputValue, setInputValue] = useState(editData.ElementName);
  const navigate = useNavigate();
  const toastId = useRef(null);
  const {elementListData,documentData,
    setElementListData,setDocumentData} = useData();

  // state to update siderba
  const {
    updateSidebarData,
    formData,
    setFormData
  } = useDms();

  // Function to handle input change with regex validation
  const validateInput = (elementName) => {
    const regex = /^[a-zA-Z0-9\s.,-]{0,100}$/;
    if (toast.isActive(toastId.current)) {
      return false;
    }
    const isValid = regex.test(elementName);
    if (!isValid) {
      toastId.current = notify("Only letters, numbers, full stop, comma and spaces are allowed with max limit of 100 characters", "error");
    }
    return isValid;
  };

  const handleSave = async (e) => {
    if (inputValue.trim() === '') {
      notify('Please enter element name.', "error");
      return;
    }
    try {
      const isValid = validateInput(inputValue);
      if (!isValid) {
        return;
      }
      e.currentTarget.disabled = true;
      const response = await putApi('updateelement', {
        id: categoryId,
        elementName: inputValue
      }, true);

      if (!response.success) {
        throw new Error('Network response was not ok');
      }
      if (response.success) {
        //get old element
        const oldElement = elementListData.find((ele) => ele.Id === categoryId);
        //console.log("=== old element ===>",oldElement);

        //update old element in context
        const newElements = elementListData.map((ele) => {
          if (ele.Id === categoryId) {
            return { ...ele, ElementName: inputValue };
          } else {
            return ele;
          }
        });
        //console.log(" === old elements ===>",elementListData);
        //console.log(" === new elements ===>",newElements);

        setElementListData(newElements);

        //update old documents in context
        const newDocuments = documentData.map((doc) => {
          if (doc.DocumentPath === oldElement.ElementName) {
            return { ...doc, DocumentPath: inputValue };
          } else {
            return doc;
          }
        });

        //update old forms in the context
        const newFormData = formData.map((form) => {
          if (form.FormPath === oldElement.ElementName) {
            return { ...form, FormPath: inputValue };
          } else {
            return form;
          }
        });
        setFormData(newFormData);

        //console.log("=== new documents ===",newDocuments);
        //console.log("=== old documents ===",documentData);
        //console.log("=== new forms ===",newFormData);
        //console.log("=== old forms ===",formData);
        setDocumentData(newDocuments);

        setTableData((prevData) => {
          const newData = [...prevData];
          const index = newData.findIndex((item) => item.Id === categoryId);
          //console.log("index: ", index)
          newData[index].ElementName = inputValue;
          return newData;
        });

        //update sidebar data
        updateSidebarData(newElements, newDocuments, newFormData);

        notify("Data saved successfully.", "success");
      }
      setModalOpen(false);
    } catch (error) {
      console.error('Error saving data:', error);
      notify('Error saving data:', "error");
    }
  };



  const handleCancel = () => {
    setModalOpen(false);
  };

  return (
    <div className="dashboard-container">
      {/* <PageHeader title="Update Category" /> */}
      <div className="container">
        <p className="label">Element Name</p>
        <input
          className="input-box"
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Element Name"
          max={100}
          min={0}
          pattern='^[a-zA-Z0-9\s.,-]*$'
        />
        <div className="BtnContainer">
          <button className="ActionButtons" onClick={handleCancel}>Cancel</button>
          <button className="ActionButtons activeBtn" onClick={(e) => handleSave(e)}>Save</button>
        </div>
      </div>
    </div>
  );
};

export default UpdateCategory;
