import React, { useEffect, useState } from "react";
import {
  IconButton,
  Paper,
  Button,
  Tooltip,
  Modal,
  Box,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./fileViewer.css";
import { useNavigate } from "react-router-dom";
import PDFViewer from "../../../../shared/components/pdfViewer/DMS_PdfViewer";
import { useDoc } from "../../../../context/docContext";
import { useAuth } from "../../../../context/authContext";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { notify } from "../../../../util/notify";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CloseIcon from "@mui/icons-material/Close";
import PrintIcon from "@mui/icons-material/Print";
import { deleteApi, getApi, postApi } from "../../../../util/HttpService";
import HtmlEditor from "../../../../shared/components/htmlEditor/htmlEditor";
import { useData } from "../../../../context/dataContext";
import PageHeader from "../../../../shared/components/pageHeader/PageHeader";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import EditMetaData from "../../../../shared/components/editMetaData/editMetaData";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../../../../shared/components/loader/loader";
import { useLocation } from "react-router-dom";
import { useDms } from "../../../../context/dmsContext";
import { customCSS } from "../../../../util/DocumentCSS";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  height: "auto",
  borderRadius: "2rem",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: "2rem",
};
export const FileViewer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { globalDocUrl, setGlobalDocUrl } = useDoc();
  const [signingLocation, setSigningLocation] = useState("");
  const [checkboxEnabled, setCheckboxEnabled] = useState(true);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const { docData } = useDoc();
  const { documentData } = useData();
  const { userId, isAdmin } = useAuth();
  const [itemList, setItemList] = useState([]);
  const [editMetaData, setEditMetaData] = useState(false);
  const { ownerDepartmentList, elementListData } = useData();
  const [editDocs, setEditDocs] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [cardData, setCardData] = useState(
    documentData?.find((d) =>
      docData ? docData?.find((item) => item?.Id === d?.Id) : null
    )
  );

  // //console.log("Doc data", documentData);
  const [ownerName, setOwnerName] = useState("");

  const { setDocumentData } = useData();
  //  const {
  //    ownerDepartmentList,
  //    elementListData,
  //    documentData: docData,
  //  } = useData();
  const { setFormInfo, setFormData, formData, updateSidebarData } = useDms();
  useEffect(() => {
    if (cardData) {
      setOwnerName(
        ownerDepartmentList.find((item) => item.Id === cardData.OwnerDepartment)
          ?.Name
      );
      //console.log("ownerName", ownerName, "cardData", cardData.OwnerDepartment);
    }
  }, [ownerDepartmentList, cardData, elementListData]);

  // const cardData = docData[0]
  useEffect(() => {
    fetchItemList();
  }, []);

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
  };

  const handlePrint = () => {
    let iframe = document.createElement("iframe");
    document.body.appendChild(iframe);

    iframe.style.display = "none";
    iframe.src = globalDocUrl;

    iframe.onload = function () {
      const styleTag = iframe.contentDocument.createElement("style");
      styleTag.textContent = customCSS;

      if (!iframe.contentDocument.head) {
        const head = iframe.contentDocument.createElement("head");
        iframe.contentDocument.documentElement.insertBefore(
          head,
          iframe.contentDocument.body
        );
      }
      iframe.contentDocument.head.appendChild(styleTag);
      console.log("ifram content", iframe.contentDocument);
      const docMetaData = `<div>
  <div
    style="
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
      padding: 1rem;
      background-color: #fff;
      border-radius: 0.5rem;
    "
  >
    <p classname="FileName" style="margin: 0; font-size: 1.2rem; ">
      ${
        cardData?.DocumentNo
          ? `${cardData.DocumentNo} | ${cardData.DocumentName}`
          : "-"
      }
    </p>
    <p classname="FileDescription" style="margin: 0;  color: #666;">
      ${cardData?.DocumentPath ? cardData.DocumentPath : "-"}
    </p>
    <div classname="CardSection" style="display: flex; gap: 1rem; flex-wrap: wrap; width: 100%;">
      <div
        classname="Card"
        style="
          width: 20%;
          padding: 0.5rem;
          border-radius: 0.5rem;
        "
      >
        <div
          classname="CardHeader"
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;

          "
        >
          <p
            classname="CardTitle"
            style="margin: 0;  "
          >
            Version
          </p>
        </div>
        <div classname="CardBody"></div>
        <p classname="CardText" style="margin: 0; ">
          ${cardData?.DocumentVersion}
        </p>
      </div>
      <div
        classname="Card"
        style="
                  width: 20%;

          padding: 0.5rem;
          border-radius: 0.5rem;
        "
      >
        <div
          classname="CardHeader"
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <p
            classname="CardTitle"
            style="margin: 0;  "
          >
            Updated Date
          </p>
        </div>
        <div classname="CardBody"></div>
        <p classname="CardText" style="margin: 0; ">
          ${
            cardData?.ModifiedOn
              ? new Date(cardData.ModifiedOn).toLocaleDateString("en-GB")
              : "-"
          }
        </p>
      </div>
      <div
        classname="Card"
        style="
                    width: 20%;

          padding: 0.5rem;
          border-radius: 0.5rem;
          
        "
      >
        <div
          classname="CardHeader"
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <p
            classname="CardTitle"
            style="margin: 0;  "
          >
            Issued Date
          </p>
        </div>
        <div classname="CardBody"></div>
        <p classname="CardText" style="margin: 0; ">
          ${new Date(cardData?.CreatedOn).toLocaleDateString("en-GB")}
        </p>
      </div>
      <div
        classname="Card"
        style="
                    width: 20%;

          padding: 0.5rem;
          border-radius: 0.5rem;
          
        "
      >
        <div
          classname="CardHeader"
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <p
            classname="CardTitle"
            style="margin: 0;  "
          >
            Owners
          </p>
        </div>
        <div classname="CardBody"></div>
        <p classname="CardText" style="margin: 0; ">
          ${ownerName}
        </p>
      </div>
    </div>
  </div>
  <div>${iframe.contentDocument.body.innerHTML}</div>
</div>`;
      iframe.contentDocument.body.innerHTML = docMetaData;

      iframe.contentDocument.body.classList.add("editor-content");
      iframe.focus();
      iframe.contentWindow.print();
      iframe.contentWindow.addEventListener("afterprint", function () {
        document.body.removeChild(iframe);
      });
    };
  };

  useEffect(() => {
    const alreadyReadItem = itemList.find(
      (item) => item.DocumentNo === cardData?.DocumentNo
    );
    // //console.log("alreadyReadItem", alreadyReadItem);
    if (alreadyReadItem) {
      setCheckboxChecked(true);
      setCheckboxEnabled(false);
      setSigningLocation(alreadyReadItem.ShipOrShore.trim() || "");
    }
  }, [itemList, cardData]);

  const fetchItemList = async () => {
    try {
      const data = await getApi(`documentreadDetailsAPI`, true);
      // //console.log("Item list", data.data.lastViewedDocuments)
      setItemList(data.data.lastViewedDocuments);
    } catch (error) {}
  };
  const addReadDocumentDetail = async () => {
    const params = {
      UserId: userId,
      DocId: cardData.Id,
      DocumentReadStatus: true,
      ShipOrShore: signingLocation,
    };
    try {
      const data = await postApi("addreadDocumentDetail", params, true);
      notify("Data added successfully", "success");
      setCheckboxChecked(true);
      setCheckboxEnabled(false);
    } catch (error) {
      notify(error.message, "error");
    }
  };

  const handleAgreementCheck = () => {
    if (!checkboxChecked && signingLocation) {
      addReadDocumentDetail();
    }
  };

  const handleSigningLocation = (e) => {
    const selectedLocation = e.target.value;
    if (selectedLocation !== "Select an Option") {
      setSigningLocation(selectedLocation);
      setCheckboxEnabled(true);
    } else {
      setSigningLocation("");
      setCheckboxEnabled(false);
    }
  };
  const handleEditMetaData = () => {
    setEditMetaData(!editMetaData);
  };

  const handleSaveDocs = () => {
    setEditDocs(!editDocs);
  };
  const handleDeleteDoc = () => {
    const response = deleteApi(`deleteDocument/${cardData.Id}`, true);

    // //console.log("response", response);
    if (response.success === false) {
      notify("Document deletion failed", "error");
    } else {
      const newData = documentData.filter((item) => item.Id !== cardData.Id);
      //console.log("newData", newData);
      setDocumentData(newData);
      updateSidebarData(elementListData, newData, formData);
      notify("Document deleted successfully", "success");

      navigate(-1);
    }
  };

  const handleEditDocs = () => {
    setEditDocs(!editDocs);
  };
  const handleNavigateBack = () => {
    navigate(-1);
  };

  return (
    <>
      {cardData ? (
        <div className="FileViewer">
          <div className="TopNav">
            <div className="fileSideItems">
              <IconButton onClick={handleNavigateBack} aria-label="back">
                <ArrowBackIcon />
              </IconButton>

              <div className="">
                <p className="FileName">
                  {cardData?.DocumentNo
                    ? `${cardData.DocumentNo} | ${cardData.DocumentName}`
                    : "-"}
                </p>
                <p className="FileDescription">
                  {cardData?.DocumentPath ? cardData.DocumentPath : "-"}
                </p>
              </div>
            </div>
            {isAdmin &&
              globalDocUrl &&
              (editMetaData ? (
                <Tooltip title="Save Document" className="d-none">
                  <IconButton className="" hidden>
                    <SaveIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <>
                  <Tooltip title="Edit Metadata">
                    <IconButton
                      className="bg-primary text-white"
                      onClick={handleEditMetaData}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Document">
                    <IconButton
                      className="bg-danger text-white"
                      onClick={handleDeleteDoc}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ))}
          </div>
          <div className="CardSection">
            <div className="Card">
              <div className="CardHeader">
                <p className="CardTitle">Version</p>
              </div>
              <div className="CardBody"></div>
              <p className="CardText">{cardData.DocumentVersion}</p>
            </div>
            <div className="Card">
              <div className="CardHeader">
                <p className="CardTitle">Updated Date</p>
              </div>
              <div className="CardBody"></div>
              <p className="CardText">
                {cardData.ModifiedOn
                  ? new Date(cardData.ModifiedOn).toLocaleDateString("en-GB")
                  : "-"}
              </p>
            </div>
            <div className="Card">
              <div className="CardHeader">
                <p className="CardTitle">Issued Date</p>
              </div>
              <div className="CardBody"></div>
              <p className="CardText">
                {new Date(cardData.CreatedOn).toLocaleDateString("en-GB")}
              </p>
            </div>
            <div className="Card">
              <div className="CardHeader">
                <p className="CardTitle">Owners</p>
              </div>
              <div className="CardBody"></div>
              <p className="CardText">{ownerName}</p>
            </div>
          </div>
          <Modal
            open={editMetaData}
            onClose={() => setEditMetaData(false)}
            aria-labelledby="edit-metadata-modal-title"
            aria-describedby="edit-metadata-modal-description"
          >
            <Box sx={style}>
              <EditMetaData
                formData={cardData}
                setEditMetaData={setEditMetaData}
                setCardData={setCardData}
              />
            </Box>
          </Modal>
          {editDocs && (
            <HtmlEditor
              setEditDocs={setEditDocs}
              DocumentData={cardData}
              editing={editDocs}
            />
          )}
          {!editDocs && (
            <>
              <div
                className={`PdfViewerContainer ${
                  fullScreen ? "fullScreenMode" : ""
                }`}
              >
                <div className="IconContainer">
                  <div
                    className="EditDocsBtn"
                    style={{ display: fullScreen ? "none" : "" }}
                  >
                    {isAdmin &&
                      globalDocUrl &&
                      (editDocs ? (
                        <Button
                          className="ActionButtons activeBtn"
                          onClick={handleSaveDocs}
                          hidden
                        >
                          Save
                        </Button>
                      ) : (
                        <Tooltip title="Edit Document">
                          <IconButton
                            className="bg-primary text-white"
                            onClick={handleEditDocs}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      ))}
                  </div>
                  {globalDocUrl && (
                    <div className="TopIcons">
                      <Tooltip
                        title={fullScreen ? "Exit Full Screen" : "Full Screen"}
                      >
                        <IconButton
                          aria-label="close"
                          onClick={handleFullScreen}
                          className="fullscreenIcon bg-secondary text-white"
                        >
                          {fullScreen ? <CloseIcon /> : <FullscreenIcon />}
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Print">
                        <IconButton
                          aria-label="print"
                          onClick={handlePrint}
                          className="printIcon bg-secondary text-white"
                        >
                          <PrintIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                </div>
                {globalDocUrl ? (
                  <PDFViewer documentUrl={globalDocUrl} />
                ) : (
                  <Skeleton height="40vh" />
                )}
              </div>
            </>
          )}
          {!globalDocUrl && <Loader />}
          <div className="AgreementContainer">
            <div className="AgreementHeader">
              <input
                type="checkbox"
                onChange={handleAgreementCheck}
                className="AgreementCheckBox"
                checked={checkboxChecked}
                disabled={!checkboxEnabled}
              />
              <p className="AgreementText">
                I have read and understand the document
              </p>
            </div>
            <div className="AgreementFooter">
              <select
                className="AgreementSelect"
                value={signingLocation}
                onChange={handleSigningLocation}
                disabled={checkboxChecked}
              >
                <option value="">Select an Option</option>
                <option value="Ship">Ship</option>
                <option value="Shore">Shore</option>
              </select>
            </div>
          </div>
        </div>
      ) : (
        <div className="FileViewerContainer">
          <PageHeader title="File Viewer" />
          {!globalDocUrl && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="60vh"
            >
              <Typography variant="h6" align="center" gutterBottom>
                No data to preview
              </Typography>
            </Box>
          )}
        </div>
      )}
    </>
  );
};
